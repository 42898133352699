import React from "react";
import "./MediaContainer.scss";

export default function MediaContainer({
  children,
  type = "image",
  appearance = "normal",
  modifier = ["normal"],
  onOpenModal,
  ...props
}) {
  const modifierClasses = modifier
    .map((mod) => `media-container--${mod}`)
    .join(" ");
  return (
    <div
      className={`media-container media-container--${appearance} ${modifierClasses}`}
      {...props}
    >
      <div className="media-container__content">{children}</div>
      {type === "video" && (
        <button aria-label="Play video" onClick={onOpenModal}>
          Play
        </button>
      )}
    </div>
  );
}
