import iconOpen from "assets/images/direct_pay/icons/icon_expand_credit.svg";
import iconGrow from "assets/images/direct_pay/icons/icon_grow_business.svg";
import iconEquipment from "assets/images/direct_pay/icons/icon_expand_market.svg";
import iconExpand from "assets/images/direct_pay/icons/icon_delivery.svg";

import iconTenSeconds from "assets/images/direct_pay/icons/icon_10second_approval.svg";
import iconLowInterest from "assets/images/direct_pay/icons/icon_low_interest_rate.svg";
import iconNextDay from "assets/images/direct_pay/icons/icon_next_day_funding.svg";

import workflowDesktop from "assets/images/direct_pay/how_it_works-desktop.svg";
import workflowMobile from "assets/images/direct_pay/how_it_works-mobile@2x.png";

import aeeSolar from "assets/images/direct_pay/partners/partner-aee_solar.svg";
import capeletric from "assets/images/direct_pay/partners/partner-capeletric.svg";
import ced from "assets/images/direct_pay/partners/partner-ced.svg";
import ces from "assets/images/direct_pay/partners/partner-ces.svg";
import codale from "assets/images/direct_pay/partners/partner-codale.svg";
import crescent from "assets/images/direct_pay/partners/partner-crescent.svg";
import crw from "assets/images/direct_pay/partners/partner-crw.svg";
import eco from "assets/images/direct_pay/partners/partner-eco.svg";
import ecopro from "assets/images/direct_pay/partners/partner-ecopro.svg";
import finalmilesolar from "assets/images/direct_pay/partners/partner-finalmilesolar.svg";
import fortuneEnergy from "assets/images/direct_pay/partners/partner-fortune_energy.svg";
import gexpro from "assets/images/direct_pay/partners/partner-gexpro.svg";
import graybar from "assets/images/direct_pay/partners/partner-graybar.svg";
import ies from "assets/images/direct_pay/partners/partner-ies.svg";
import krannich from "assets/images/direct_pay/partners/partner-krannich.svg";
import leveredge from "assets/images/direct_pay/partners/partner-leveredge.svg";
import nextEnergyAlliance from "assets/images/direct_pay/partners/partner-next_energy_alliance.svg";
import onesource from "assets/images/direct_pay/partners/partner-onesource.svg";
import rexel from "assets/images/direct_pay/partners/partner-rexel.svg";
import soligent from "assets/images/direct_pay/partners/partner-soligent.svg";
import sonepar from "assets/images/direct_pay/partners/partner-sonepar.svg";
import sunrgy from "assets/images/direct_pay/partners/partner-sunrgy.svg";
import uma from "assets/images/direct_pay/partners/partner-uma.svg";
import usRenewableSolutions from "assets/images/direct_pay/partners/partner-us_renewable_solutions.svg";
import wesco from "assets/images/direct_pay/partners/partner-wesco.svg";

export const benefits = [
  {
    icon: iconOpen,
    title: "OPEN YOUR CREDIT LINES",
    description:
      "Get the working capital you need to grow sales and marketing, business operations and scale your install pipeline.",
  },
  {
    icon: iconGrow,
    title: "GROW YOUR BUSINESS",
    description:
      "Increase your conversion rates and grow even more - we can help you improve your conversion rate by as much as 30%.",
  },
  {
    icon: iconEquipment,

    title: "EXPAND YOUR MARKET",
    description:
      "Help more people go solar by offering best-in-class financing.",
  },
  {
    icon: iconExpand,
    title: "EQUIPMENT DELIVERED",
    description:
      "Get equipment delivered on-site with no out-of-pocket expenses.",
  },
];

export const features = [
  { icon: iconTenSeconds, title: "10-second approvals" },
  { icon: iconLowInterest, title: "Highly competitive interest rates" },
  { icon: iconNextDay, title: "Next day funding" },
];

export const partners = [
  { src: aeeSolar, alt: "AEE Solar " },
  { src: capeletric, alt: "Capital Electric - A Sonepar Company" },
  { src: ced, alt: "CED Greentech Riverside" },
  { src: ces, alt: "City Electric Supply" },
  { src: codale, alt: "Codale" },
  { src: crescent, alt: "Crescent Electric Supply Company" },
  { src: crw, alt: "Crawford - A Sonepar Company" },
  { src: eco, alt: "Eco Distributing" },
  { src: ecopro, alt: "Ecopro Solar Supply" },
  { src: finalmilesolar, alt: "Final Mile Solar" },
  { src: fortuneEnergy, alt: "Fortune Energy" },
  { src: gexpro, alt: "Gexpro" },
  { src: graybar, alt: "Graybar" },
  { src: ies, alt: "Independent Electric - A Sonepar Company" },
  { src: krannich, alt: "Krannich" },
  { src: leveredge, alt: "Leveredge" },
  { src: nextEnergyAlliance, alt: "Next Energy Alliance" },
  { src: onesource, alt: "OneSource - A Sonepar Company" },
  { src: rexel, alt: "Rexel" },
  { src: soligent, alt: "Soligent" },
  { src: sonepar, alt: "Sonepar" },
  { src: sunrgy, alt: "Sunrgy Solar Distribution" },
  { src: uma, alt: "UMA Solar" },
  { src: usRenewableSolutions, alt: "U.S. Renewable Solutions" },
  { src: wesco, alt: "Wesco" },
];

export const howItWorks = {
  desktop: workflowDesktop,
  mobile: workflowMobile,
};

export default { benefits, features, partners, howItWorks };
