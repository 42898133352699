import React, { useState } from "react";
import { navigate } from "gatsby";

import { Modal } from "react-responsive-modal";

import Main from "components/layout/Main";
import Heading from "components/Heading";
import Paragraph from "components/Paragraph";
import Button from "components/Button";
import HeaderSkew from "components/HeaderSkew";
import Block from "components/Block";
import Picture from "components/Picture";
import ContentGrid from "components/ContentGrid";
import JumbotronCard from "components/JumbotronCard";
import GridCards from "components/GridCards";
import CardBenefit from "components/CardBenefit";
import PartnerLogo from "components/PartnerLogo";
import MediaContainer from "components/MediaContainer";
import backgroundCover from "assets/images/direct_pay/directpay_header.jpg";
import directPayHeader from "assets/images/direct_pay/directpay_header_offset.jpg";

import ParagraphPointer from "components/ParagraphPointer";

import PartnerIntakeForm from "components/forms/PartnerIntakeForm";

import ScrollToTop from "components/ScrollToTop";

import { benefits, features, partners, howItWorks } from "data/page-direct-pay";

function offsetImage() {
  return (
    <>
      <MediaContainer>
        <Picture
          src={directPayHeader}
          webp={directPayHeader}
          type="clickable"
          width="630"
          height="380"
          alt="Know your loan. Love your lender."
        />
      </MediaContainer>
    </>
  );
}

export default function DirectPay() {
  const TagOffsetImage = offsetImage;

  const [openFormPartnerIntake, setOpenFormPartnerIntake] = useState(false);

  return (
    <Main page={"direct-pay"} pagetitle="Direct Pay Program">
      <HeaderSkew
        details={false}
        title="Direct Pay Program"
        subtitle="We’ll handle the bill.
        You grow the business."
        background={backgroundCover}
        offsetImage={offsetImage}
        offsetImageMobile={true}
        url="direct-pay"
      />
      <div className="pointer-parent">
        <ParagraphPointer linkedTo="#dp0" />
        <ParagraphPointer linkedTo="#dp1" />
        <ParagraphPointer linkedTo="#dp2" />
        <ParagraphPointer linkedTo="#dp3" />

        <Block layer="1" guides={1} appearance="direct-pay-top">
          <ContentGrid appearance="main-double">
            <>
              <Heading type="h2" appearance="h3-static" id="dp0">
                Sign-up today and let’s grow, together.
              </Heading>

              <Paragraph appearance="normal">
                We’ve partnered with top distributors across the country to
                create a program where we pay them directly for equipment, so
                you can unleash more capital to grow your business. Plus, you’ll
                be able to join and enjoy the benefits of Loanpal’s
                industry-leading platform.
              </Paragraph>

              <Button
                handleClick={() => setOpenFormPartnerIntake(true)}
                appearance="slim-ghost"
                caret
                aria-label="Open form"
              >
                Get started
              </Button>
            </>
          </ContentGrid>

          <ContentGrid appearance="direct-pay-benefits">
            <GridCards modifier={["2", "spacer"]}>
              {benefits.map((benefit) => (
                <CardBenefit
                  {...benefit}
                  modifier={["normal"]}
                  key={benefit.title}
                />
              ))}
            </GridCards>
          </ContentGrid>
        </Block>

        <Block layer="1" guides={1} appearance="direct-pay-apply">
          <ContentGrid appearance="centered">
            <>
              <Heading type="h2" appearance="h2" id="dp1">
                {" "}
                Apply today!
              </Heading>

              <Paragraph appearance="normal" style={{ maxWidth: "490px" }}>
                Want to dramatically increase your business quickly? Join Direct
                Pay, and get access to Loanpal’s industry-leading payment
                options featuring:
              </Paragraph>

              <GridCards modifier={["3", "fullwidth"]}>
                {features.map((feature) => (
                  <CardBenefit
                    {...feature}
                    modifier={["simple"]}
                    key={feature.title}
                  />
                ))}
              </GridCards>

              <Button
                handleClick={() => setOpenFormPartnerIntake(true)}
                appearance="slim-ghost"
                caret
                aria-label="Open form"
              >
                Get started
              </Button>
            </>
          </ContentGrid>
        </Block>

        {/* How it works */}
        <Block layer="1" guides={1} appearance="gray-skew-desktop">
          <ContentGrid appearance="centered">
            <>
              <Heading type="h2" appearance="h2" id="dp2">
                How it works
              </Heading>
              <div className="box-graphic desktop-only">
                <img src={howItWorks.desktop} alt="How it works" />
              </div>
              <div className="center-image mobile-only">
                <img
                  src={howItWorks.mobile}
                  alt="How it works"
                  style={{ marginTop: "64px" }}
                  className="mobile-only"
                />
              </div>
            </>
          </ContentGrid>
        </Block>
      </div>
      {/* Requirements */}
      <Block layer="1" guides={1} appearance="direct-pay-requirements">
        <ContentGrid appearance="centered">
          <>
            <JumbotronCard type="in-content">
              <h3>We ask that our partners meet the following requirements:</h3>
              <ul>
                <li>
                  <p>Minimum of 100 installations over company lifetime.</p>
                </li>
                <li>
                  <p>
                    Satisfactory business credit, online reputation, business
                    financials, and Better Business Bureau Rating.
                  </p>
                </li>
                <li>
                  <p>
                    Personal credit on ownership when the company has less than
                    500 installs to date.
                  </p>
                </li>
              </ul>
            </JumbotronCard>
          </>
        </ContentGrid>
      </Block>
      {/* Partners */}
      <Block layer="1" guides={1} appearance="direct-pay-partners">
        <ContentGrid appearance="centered">
          <Heading type="h2" appearance="h3-static" id="dp3">
            Our Partners
          </Heading>
          <GridCards modifier={["5", "fullwidth"]}>
            {partners.map((partnet) => (
              <PartnerLogo {...partnet} key={partnet.alt} />
            ))}
          </GridCards>
        </ContentGrid>
      </Block>
      <Modal
        open={openFormPartnerIntake}
        onClose={() => setOpenFormPartnerIntake(false)}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customForm",
        }}
      >
        <>
          <PartnerIntakeForm
            emailTo="EHowarth@loanpal.com"
            emailToCopy="LRighos@loanpal.com"
          />
        </>
      </Modal>
      {/* */}
    </Main>
  );
}
