import React from "react";
import "./CardBenefit.scss";

export default function CardBenefit({
  icon = false,
  title = "",
  description = "",
  modifier = ["normal"],
  phone = false,
  tollFree = false,
  schedule = false,
  email = false,
}) {
  const modifierClasses = modifier
    .map((mod) => `card-benefit--${mod}`)
    .join(" ");
  return (
    <div className={`card-benefit ${modifierClasses}`}>
      {icon && <img src={icon} alt={title} className="card-benefit__icon" />}
      {title && (
        <h3
          className="card-benefit__title"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      )}
      {description && (
        <p className="card-benefit__description">{description}</p>
      )}
      {phone && (
        <p className="card-benefit__phone">
          <a
            className="card-benefit__phone"
            href={`tel:${phone}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline" }}
          >
            {phone}
          </a>
          {tollFree && " (TOLL FREE)"}
        </p>
      )}
      {schedule &&
        schedule.map((item) => (
          <p className="card-benefit__schedule">{item.day}</p>
        ))}
      {email && (
        <p className="card-benefit__email">
          <a
            className="card-benefit__email"
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {email}
          </a>
        </p>
      )}
    </div>
  );
}
