import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import FormSubmit from "components/forms/FormSubmit";
import InputText from "components/forms/InputText";
import FormSuccess from "components/forms/FormSuccess";
import FormError from "components/forms/FormError";
import FormLegals from "components/forms/FormLegals";

import { schema, resolver } from "data/form-schemas/partner-intake.js";
import { sendContactForm } from "services/index.js";

// import "./ContactForm.scss";

export default function PartnerIntakeForm({
  emailTo = "partnerapproval@loanpal.com",
  emailToCopy = false,
}) {
  const [formStatus, setFormStatus] = useState("WAIT");
  const [submited, setSubmited] = useState(false);

  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(resolver),
  });

  const onSubmit = async ({
    name,
    company,
    emailFrom,
    phone,
    installs,
    statesOfOperation,
  }) => {
    setSubmited(true);

    const formData = {
      name: name,
      company: company,
      emailFrom: emailFrom,
      phone: phone,
      installs: installs,
      statesOfOperation: statesOfOperation,
      emailTo: emailTo,
      subject: `Loanpal Partner Intake inquiry from ${name}`,
    };

    try {
      const response = await sendContactForm(formData);
      if (!emailToCopy) {
        setFormStatus("SENT");
      }
    } catch (err) {
      setFormStatus("ERROR");
    }

    if (emailToCopy) {
      try {
        const response = await sendContactForm({
          ...formData,
          emailTo: emailToCopy,
        });
        setFormStatus("SENT");
      } catch (err) {
        setFormStatus("ERROR");
      }
    }

    setSubmited(false);
  };

  return (
    <div className="contact-form">
      {formStatus === "SENT" && <FormSuccess />}

      {formStatus === "ERROR" && <FormError emailTo={emailTo} />}

      {formStatus === "WAIT" && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>
            With a platform customized for your business, we make the financing
            process fast and friendly for you{" "}
            <span style={{ textDecoration: "underline", fontStyle: "italic" }}>
              and
            </span>{" "}
            your customers. <br /> Tell us a little bit about you.
          </h2>

          <p className="disclaimer">
            Required fields <span>*</span>
          </p>

          <InputText
            {...schema.name}
            register={register}
            errors={errors["name"]}
            value={watch("name")}
            isRequired={schema["name"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.company}
            register={register}
            errors={errors["company"]}
            value={watch("company")}
            isRequired={schema["company"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.emailFrom}
            register={register}
            errors={errors["emailFrom"]}
            value={watch("emailFrom")}
            isRequired={schema["emailFrom"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.phone}
            register={register}
            errors={errors["phone"]}
            value={watch("phone")}
            isRequired={schema["phone"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.installs}
            register={register}
            errors={errors["installs"]}
            value={watch("installs")}
            isRequired={schema["installs"].validation.exclusiveTests.required}
          />

          <InputText
            {...schema.statesOfOperation}
            register={register}
            errors={errors["statesOfOperation"]}
            value={watch("statesOfOperation")}
            isRequired={
              schema["statesOfOperation"].validation.exclusiveTests.required
            }
          />

          <FormLegals />

          <FormSubmit data-emailto={emailTo} disabled={submited} />
        </form>
      )}
    </div>
  );
}
