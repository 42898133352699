import * as yup from "yup";

export const schema = {
  name: {
    name: "name",
    id: "contact-name",
    type: "text",
    label: "Name",
    placeholder: "Enter Name",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },

  company: {
    name: "company",
    id: "contact-company",
    type: "text",
    label: "Company Name",
    placeholder: "Enter Company Name",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },

  emailFrom: {
    name: "emailFrom",
    id: "contact-emailFrom",
    type: "email",
    label: "Email",
    placeholder: "Enter Email",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().email().required(),
  },

  phone: {
    name: "phone",
    id: "contact-phone",
    type: "text",
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },

  installs: {
    name: "installs",
    id: "contact-installs",
    type: "text",
    label: "Average Installs per month",
    placeholder: "Enter Average Installs per month",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  },

  statesOfOperation: {
    name: "statesOfOperation",
    id: "contact-statesOfOperation",
    type: "text",
    label: "States that you operate in",
    placeholder: "Enter States that you operate in",
    defaultValue: "",
    errorMessage: "",
    validation: yup.string().required(),
  }
};

const schemaShape = Object.keys(schema).reduce((result, key) => {
  result[key] = schema[key].validation;
  return result;
}, {});



export const resolver = yup.object().shape(schemaShape);

export default {
  schema,
  resolver
};
