import React from "react";
import "./PartnerLogo.scss";

export default function PartnerLogo({ src, alt }) {
  return (
    <div className="partnet-logo">
      <img src={src} alt={alt} className="partnet-logo__picture" />
    </div>
  );
}
